import { withAuthenticator,
	 Button,
	 Text,
	 Heading,
	 Divider,
	 View,
	 ScrollView,
	 Collection,
	 Flex,
	 Grid,
	 Card,
	 Image,
	 Loader
 } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.layer.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer,
	 TileLayer,
	 Marker,
	 Polygon,
	 Tooltip,
	 Polyline,
	 GeoJSON,
	 Popup } from 'react-leaflet';
import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getUrl } from 'aws-amplify/storage';
import { StorageImage } from '@aws-amplify/ui-react-storage';
import { Amplify } from 'aws-amplify';
import { get } from 'aws-amplify/api';


Amplify.configure({
    Auth: {
        Cognito: {
	    userPoolId: process.env.REACT_APP_USER_POOL_ID,
	    userPoolClientId: process.env.REACT_APP_CLIENT_ID,
	    // We would need this to use the storage plugin (there is
	    //no identityId on the auth object otherwise), but using
	    //it breaks the API completely ("Invalid identity pool configuration. Check assigned IAM roles for this pool.")

	    // identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
	    loginWith: {
		oauth: {
		    domain: process.env.REACT_APP_COGNITO_DOMAIN,
		    scopes: [
			'email',
			'profile',
			'openid',
			'aws.cognito.signin.user.admin'
		    ],
		    redirectSignIn: [process.env.REACT_APP_SIGN_IN_REDIRECT],
		    redirectSignOut: ['https://www.swee.ai'],
		    responseType: 'code',
		    providers: ["Google", "Apple"]
		}}}},
    API: {
	REST: {
	    'admin-api' : {
		endpoint: process.env.REACT_APP_API_URL,
		region: 'us-west-2'
	    }}},
    Storage: {
	S3: {
	    // Default bucket must be repeated here.
	    bucket: process.env.REACT_APP_BRANDING_BUCKET,
	    region: 'us-west-2',
	    buckets: {
		'brandingBucket': {
		    bucketName: process.env.REACT_APP_BRANDING_BUCKET,
		    region: 'us-west-2'
		}
	    }
	}}},
    {
	API: {
			  REST: {
			      headers: async () => {
				  return { Authorization: (await fetchAuthSession()).tokens?.accessToken?.toString() };
			      }}}});

let didInit = false;

async function getCourses() {
    const restOperation = get({
	apiName: 'admin-api',
	path: '/courses'
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
};

// Apparently the following is necessary to get the Marker to display
// See: https://github.com/PaulLeCam/react-leaflet/issues/453

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25,41],
    iconAnchor: [12, 41],
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

function GeoJSON_to_center(gj) {
    // Also "Point", and what else? "MultiPolygon" maybe.
    let center = [];
    switch (gj.type) {
    case "Polygon": {
        center = gj.coordinates[0][0].slice().reverse();
        break;
    }
    case "LineString": {
        center = gj.coordinates[0].slice().reverse();
        break;
    }
    case "Point": {
        center = gj.coordinates.slice().reverse();
        break;
    }
    default: {
        break;
    }}
    return center;
}

function ROIMap({rois, center}) {

    let layers = rois.concat(rois.flatMap((r) => (r.devices === null ? [] : r.devices)));

    function makeLayer (obj, i) {
	return <GeoJSON key={i} data={obj.geojson}>
		   <Tooltip permanent offset={[10, 10]}>{obj.label}</Tooltip>
	       </GeoJSON>
    };
    return <View>
	       <MapContainer style={{width: '100%', height: '70vh'}} center={center} zoom={16} scrollWheelZoom={false}>
		   <TileLayer
		       attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		   />
		   {layers.map(makeLayer)}
	       </MapContainer>
           </View>
};

function Course({course, rois}) {
    let course_rois = course.rois.map((roiid) => rois[roiid]);
    return (
    <Card>
	<Heading level={3}>{ course.coursename }</Heading>
	<Text>{ course.courseaddress }</Text>
	{ Object.hasOwn(course.settings, 'logo') ?
	 <Text>Logo goes here </Text> : '' }
	{ course_rois.length ?
	  <>
	<Collection items={course_rois} type="grid" templateColumns="1fr 1fr 1fr">
	    {(roi) => <Card key={roi.roiid}>
			  <Heading level={6}>{roi.label}</Heading>
			  {roi.devices !== null ? roi.devices.map((d) => (<Text key={d.device_name}>{d.label}</Text>)) : ''}
		      </Card>}
		      </Collection>
	    <ROIMap key={course.courseid} rois={course_rois} center={GeoJSON_to_center(course.course_center)} /> </> : <Text>This course has no ROIs</Text>}
    </Card>)
};

function Site({ signOut, user }) {

    const [courses, setCourses] = useState({});
    const [rois, setRois] = useState({});
    const [courseOrder, setCourseOrder] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    let selectedCourse = selectedCourseId ? courses[selectedCourseId] : null;

    useEffect(() => {
	if (!didInit) {
	    didInit = true;
	    setIsLoading(true);
	    getCourses().then(json => {
		setCourses(json['courses']);
		setRois(json['rois']);
		setCourseOrder(json['course_order']);
		setIsLoading(false);
	    }).catch(error => console.log(error.message));
	}
    }, []);

    return <Grid
	       templateColumns="2fr 3fr"
	       rowGap="2rem"
	       columnGap="2rem"
	       alignContent="right"
	   >
	       <View
	       	   columnStart="1"
		   columnEnd="-1"
	       >
		   <Heading
		       level={1}
		       height="10vh"
		   >
		       SWEE Admin Backend
		   </Heading>
		   <Button
		       onClick={signOut}
		   >
		       sign out
		   </Button>
	       </View>
	       <ScrollView height="80vh">
		   { isLoading ? <><Text>Loading courses...</Text><Loader variation="linear" /></> :
		   <Collection
		       items={courseOrder}
		       gap="5px"
		       columnStart="1"
		       columnEnd="2"
		   >
		       {(id, idx) => {
			   let c = courses[id];
			   return <Card key={id}
					onClick={() => setSelectedCourseId(id)}
				  >
				      <Heading level={4}>{c.coursename}</Heading>
				      <Text>{c.courseaddress}</Text>
				  </Card>}}
		   </Collection>
		   }
	       </ScrollView>
	       <View
		   columnStart="2"
		   columnEnd="-1"
	       >
		   { selectedCourse ? <Course course={selectedCourse} rois={rois} /> : <Text>Please select a course</Text> }
	       </View>
	   </Grid>
};

export default withAuthenticator(Site, {hideSignUp: true});
